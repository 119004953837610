let injected = false;
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.promise";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.typed-array.includes";
import "core-js/modules/es.reflect.apply";

// Create a namespace and attach function that will store captured exception
// Because functions are also objects, we can attach the queue itself straight to it and save some bytes
let queue = function (content) {
    queue.data.push(content);
    // content.e = error
    // content.p = promise rejection
    // content.f = function call the Sentry
    if (content.e ||
        content.p ||
        (content.f && content.f.indexOf('capture') > -1) ||
        (content.f && content.f.indexOf('showReportDialog') > -1)
    ) {
        // We only want to lazy inject/load the sdk bundle if
        // an error or promise rejection occured
        // OR someone called `capture...` on the SDK
        injectSdk();
    }
};
queue.data = [];

function injectSdk() {
    if (injected) {
        return;
    }
    injected = true;

    import(/* webpackChunkName: "sentry-browser" */ '@sentry/browser').then(Sentry => {
        window['onerror'] = _oldOnerror;
        window['onunhandledrejection'] = _oldOnunhandledrejection;
        window.Sentry = Sentry;
        sdkLoaded(Sentry);
    });
}

function sdkLoaded(SDK) {
    try {
        let data = queue.data;
        // We want to replay all calls to Sentry first to make sure init is called before
        // we call all our internal error handlers
        let firstInitCall = false;
        let calledSentry = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].f) {
                calledSentry = true;
                let call = data[i];
                firstInitCall = true;
                SDK[call.f].apply(SDK, call.a);
            }
        }
        // Because we installed the SDK, at this point we have an access to TraceKit's handler,
        // which can take care of browser differences (eg. missing exception argument in onerror)
        let tracekitErrorHandler = window['onerror'];
        let tracekitUnhandledRejectionHandler = window['onunhandledrejection'];

        // And now capture all previously caught exceptions
        for (let i = 0; i < data.length; i++) {
            if (data[i].e && tracekitErrorHandler) {
                tracekitErrorHandler.apply(window, data[i].e);
            } else if (data[i].p && tracekitUnhandledRejectionHandler) {
                tracekitUnhandledRejectionHandler.apply(window, [data[i].p]);
            }
        }
    } catch (o_O) {
        console.error(o_O);
    }
}

window['Sentry'] = {};

[
    'init',
    'addBreadcrumb',
    'captureMessage',
    'captureException',
    'captureEvent',
    'configureScope',
    'withScope',
    'showReportDialog'
].forEach(function (f) {
    window['Sentry'][f] = function () {
        queue({f: f, a: arguments});
    };
});

Sentry.init(window.sentry_options);
if (window.sentry_user_context !== null) {
    Sentry.configureScope(function (scope) {
        scope.setUser(window.sentry_user_context);
    });
}

// Store reference to the old `onerror` handler and override it with our own function
// that will just push exceptions to the queue and call through old handler if we found one
let _oldOnerror = window['onerror'];
window['onerror'] = function (message, source, lineno, colno, exception) {
    // Use keys as "data type" to save some characters"
    queue({
        e: [].slice.call(arguments)
    });

    if (_oldOnerror) _oldOnerror.apply(window, arguments);
};
// Do the same store/queue/call operations for `onunhandledrejection` event
let _oldOnunhandledrejection = window['onunhandledrejection'];
window['onunhandledrejection'] = function (exception) {
    queue({
        p: exception.reason
    });
    if (_oldOnunhandledrejection) _oldOnunhandledrejection.apply(window, arguments);
};

console.log('Sentry setup complete');